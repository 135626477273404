import ActivitiesSearchSection from '@/components/activities/search-section';
import ArticlesSearchSection from '@/components/articles/search-section';
import ArticleThumbnail from '@/components/articles/thumbnail';
import PractitionersSearchSection from '@/components/practitioners/search-section';
import { Link } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';

const ArticleTemplate = ({ pageContext: { article, randomArticles } }) => {
  let contentUpdated = article.content.html || '';
  contentUpdated = contentUpdated.replace(/<br \/>/g, '');

  return (
    <Layout>
      <ArticlesSearchSection />
      <div className="section">
        <div className="container content">
          <h1 dangerouslySetInnerHTML={{ __html: article.title }} />
          <div dangerouslySetInnerHTML={{ __html: contentUpdated }} />
        </div>
      </div>
      <PractitionersSearchSection />
      <div className="section has-background-primary-light">
        <div className="container">
          <h2 className="title has-text-centered is-3 mb-5">
            Consultez notre base d&apos;articles
          </h2>
          <div className="columns is-multiline is-mobile is-justify-content-space-around">
            {randomArticles.map((article) => (
              <div className="column is-6-mobile is-3-tablet" key={article._id}>
                <ArticleThumbnail article={article} />
              </div>
            ))}
          </div>
          <p className="has-text-centered mt-5">
            <Link
              to="/articles"
              className="button is-primary has-text-weight-bold"
            >
              Rechercher dans tous les articles
            </Link>
          </p>
        </div>
      </div>
      <ActivitiesSearchSection />
    </Layout>
  );
};

export default ArticleTemplate;
